.navbar {
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .menuBtn {
  padding: 8px 16px;
  border-radius: 24px;
  color: var(--white-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--transparent-black);
  transition: all 0.3s ease-in-out;
  align-items: center;
  gap: 8px;
  font-family: var(--font-mono);
  text-transform: uppercase;
  border: None;
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.menuBtn:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.menuBtnDark {
  padding: 8px 16px;
  border-radius: 24px;
  color: var(--white-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--transparent-black);
  transition: all 0.3s ease-in-out;
  align-items: center;
  gap: 8px;
  font-family: var(--font-mono);
  text-transform: uppercase;
  border: None;
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.menuBtnDark:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(11, 25, 33, 0.88) 0%, rgba(36, 75, 114, 0.88) 40%, rgba(14, 51, 70, 0.88) 80%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Keep the modal on top */
  padding: var(--padding-5xl) var(--padding-21xl); /* Adjustable padding */
  box-sizing: border-box;
  backdrop-filter: blur(15px); /* Apply blur effect for the frosted glass look */
  -webkit-backdrop-filter: blur(15px); /* Safari compatibility */

}

.ribbonText {
  position: relative;
  line-height: 1.67rem;
  font-family: var(--font-mono);
  font-size: var(--regular-6-size);
}

.ribbonText > .ribbonLink {
  color: var(--primary-color) !important;
}

.ribbon {
  align-self: stretch;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  min-height: 4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-5xl);
}

.modalContent {
  /*background-color: var(--white-color);*/
  width: 100%; /* Full viewport width with 20px margin on each side */
  height: 100%;
  /*margin: 20px;*/
  max-width: var(--max-width);
  overflow-y: auto;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  display: flex;
  flex-direction: column;
  padding: 0;
}

.modal h2 {
  margin: 0 0 10px 0;
}

.modal a {
  color: var(--secondary-color);
  text-decoration: none;
}

.modalHeader {
  color: var(--secondary-color);
}

.modalHeader,
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
  flex-shrink: 0; /* Prevent flex items from shrinking below their initial size */
}

.modalFooter {
  padding: 0;
}

.cwLogo {
  position: relative;
}

.vectorIcon {
  position: relative;
  width: 16.3rem;
  /*height: 3rem;*/
  flex-shrink: 0;
  /*mix-blend-mode: multiply;*/
  vertical-align: middle;
}

.cwBetaBadge {
  padding: 4px 8px;
  text-align: center;
  border-radius: 20px;
  background-color: var(--primary-color);
  font-family: var(--font-mono);
  font-size: 10px;
  color: var(--secondary-color);
  margin-left: 4px;
  line-height: normal;
  position: absolute;
  right: -53px;
  top: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.modalFooter {
  /*border-top: 1px solid var(--grey-2-color);*/
  gap: 20px;
}

.modalTitle {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: var(--font-mono);
  font-size: var(--regular-6-size);
}

.modalBody {
  overflow-y: auto;
  padding: 20px 0;
  gap: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.knowledgeHubContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  width: 100%;
}

.knowledgeHubRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex: 1;
}

.knowledgeHubRow > * {
  flex: 1; /* Makes each item in the row equally wide */
}

.knowledgeHubCategory {
  font-family: var(--font-primary);
  letter-spacing: 0.05rem;
  text-transform: initial;
  align-content: center;
  background-color: var(--transparent-black);
  padding: 8px;
  border-radius: 8px;
}

.knowledgeHubCategory:hover {
  background-color: var(--transparent-white);
}

.column {
  box-sizing: border-box;
}

.closeButton {
  cursor: pointer;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  color: var(--primary-color);
  border-top: 1px solid var(--primary-color);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-family: var(--font-mono);
  font-size: var(--regular-6-size);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}

.category {
  font-family: var(--font-primary);
  font-size: 2rem;
  color: var(--white-color);
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 0;
  position: relative;
}

.category > a {
  text-decoration: none;
  transition: all 0.3s ease;
}

.category > a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.subcategory {
  font-family: var(--font-primary);
  letter-spacing: normal;
  font-weight: normal;
  text-transform: none;
  color: white;
  display: flex;

  flex-direction: column;
  gap: 8px;
  align-items: stretch;
  border: 1px solid transparent;
  font-size: 1.6rem;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  cursor: pointer;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
  background-color: rgb(255 255 255 / 13%);
}

.subcategory:hover {
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
}

.active {
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
}

.one_column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 calc(25% - 20px);
}

.two_columns {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex: 1 0 calc(50% - 12px);
}

.categoryItems {
}

.categoryItems .section {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.section > :first-child {
  flex: 1 0 100%;
  color: var(--primary-color);
}

.category {
  flex: 1 0 calc(50% - 8px);
}

.subMenu {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  background-color: var(--secondary-color);
  color: var(--white-color);
  /*min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
  /* background: linear-gradient(var(--secondary-color), var(--secondary-color))
         padding-box,
       linear-gradient(
           60deg,
           var(--purple-color),
           var(--primary-color),
           var(--green-1-color),
           var(--yellow-color)
         )
         border-box;
     border: 1px solid transparent;
     padding: 8px 8px;*/
  padding: 8px 0;
  border-radius: var(--border-radius);
  /*left:0;
    right: 0; */
  z-index: 100;
}

.subMenuItem {
  color: var(--white-color);
  padding: 4px 8px;
  text-decoration: none;
  display: block;
  border-radius: var(--border-radius);
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 8px;

  background-color: rgb(255 255 255 / 13%);
  /*transition: all ease-in-out 0.2s;*/
  backdrop-filter: blur(8px);
  border: 1px solid transparent;
}

.subMenuItem:hover {
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
}

.headerElement {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.iconTitle {
  display: flex;
  gap: 8px;
  color: var(--white-color);
  text-align: left;
}

.caret {
  color: var(--primary-color);
}

.post {
  position: relative;
  width: 100%;
  color: var(--white-color);
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: rgb(255 255 255 / 13%);
  border-radius: 8px;
  border: 1px solid transparent;
}

.post:hover {
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
}

.postCard {
  display: flex;
  flex-direction: column;
}

.postImage {
  width: 100%;
  height: auto;

  max-height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.postInfo {
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding: 14px 16px 16px 16px;
}

.postInfo .title {
  font-size: 1.6rem;
  color: var(--white-color);
  line-height: normal;
  font-weight: 600;
}

.postInfo .description {
  line-height: normal;
  color: var(--white-color);
}

.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
  align-items: stretch; /* Ensures all items stretch to match the tallest item in the row */
}

/* Default: Half-Width Tool */
.toolHalf {
  flex: 1 0 calc(50% - 8px); /* Adjusted for gap */
}

/* Full-Width Tool */
.toolFull {
  flex: 1 0 100%;
}

.tool {
  position: relative;
  width: 100%;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 8px;
  background-color: rgb(255 255 255 / 13%);
  border-radius: 8px;
  border: 1px solid transparent;
  align-items: center;
  text-align: center;
  height: 100%; /* Ensures the tool stretches to fill the container's height */
}

.toolIcon {
  font-size: 3rem;
}

.toolTitle {
  font-family: var(--font-primary);
  text-transform: capitalize;
}

.tool:hover {
  background:
    linear-gradient(var(--secondary-color), var(--secondary-color)) padding-box,
    linear-gradient(60deg, var(--purple-color), var(--primary-color), var(--green-1-color), var(--yellow-color))
      border-box;
  border: 1px solid transparent;
}

.category > .moreA {
  text-decoration: none;
  color: var(--white-color);
  transition: all 0.3s ease;
}

.category > .moreA:hover {
  text-decoration: none;
  color: var(--primary-color);
}

.moreLink {
  font-size: 1.4rem;
  font-weight: normal;
  display: flex;
  font-family: var(--font-mono);
  color: var(--white-color);
  gap: 8px;
  transition: all 0.3s ease;
}

.moreLink:hover {
  gap: 10px;
  color: var(--primary-color);
}

.moreLink::after {
  content: '';
  position: absolute;
  left: 0;
  width: 24px;
  bottom: 0;
  height: 1px;
  background-color: var(--white-color);
  transition: all 0.3s ease;
}

.moreLink:hover::after {
  width: 40px;
  background-color: var(--primary-color);
}

.moreLabel {
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.moreIcon {
  font-size: 1.6rem;
}

@keyframes blurFadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(8px);
  }
}

/* Mobile */
@media (max-width: 700px) {
  .modal {
    padding: 0;
  }

  .modalContent {
    padding: 24px;
    padding-top: var(--padding-5xl);
  }

  .one_column {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    flex: 0 0 100%;
  }

  .two_columns {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex: 0 0 100%;
  }

  .tools {
    display: flex;
    flex-direction: column;
  }

  .tools > * {
    flex: 1 0 100%;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .one_column {
    flex: 1 0 calc(25% - 20px);
  }

  .tools {
    display: flex;
    flex-direction: column;
  }

  .tools > * {
    flex: 1 0 100%;
  }
}
