.ribbonText {
  position: relative;
  line-height: 1.67rem;
  font-family: var(--font-mono);
  font-size: var(--regular-6-size);
}

.ribbonLink {
  color: var(--primary-color);
}

.ribbon {
  align-self: stretch;
  background-color: var(--secondary-color);
  min-height: 4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-5xl);
}
.vectorIcon {
  position: relative;
  width: 16.3rem;
  /*height: 3rem;*/
  flex-shrink: 0;
  /*mix-blend-mode: multiply;*/
  vertical-align: middle;
}
.button1 {
  position: relative;
  line-height: 2.67rem;
}
.navbarButtonsCenter {
  /*display: flex;*/
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.text {
  position: relative;
  line-height: 1.67rem;
  font-weight: 600;
}
.navbarButtonsRight {
  border-radius: var(--br-81xl);
  background-color: var(--primary);
  height: 4rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
}
.navbar,
.navbar1,
.navbarButtons {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.navbarButtons {
  flex-direction: row;
  justify-content: space-between;
}

.navbar1 {
  background-color: var(--white);
  padding: var(--padding-5xl) var(--padding-21xl);
  justify-content: center;
  text-align: left;
  color: var(--secondary-color);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}

.darkNav {
  background: linear-gradient(0deg, #05293d00, #05293dbd);
  color: var(--white-color);
}

.transparentNav {
  background-color: transparent !important;
  color: var(--white-color);
}

.navbar {
  justify-content: flex-start;
  text-align: center;
  font-size: var(--medium-7-size);
  color: var(--primary-color);
  font-family: var(--medium-7);
  flex-direction: column;

  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}

.fixedNav {
  position: fixed;
}

.navbarContainer {
  max-width: var(--max-width);
  width: 100%;
}

.cwLogo {
  position: relative;
}

.cwBetaBadge {
  padding: 4px 8px;
  text-align: center;
  border-radius: 20px;
  background-color: var(--grey-1-color);
  font-family: var(--font-mono);
  font-size: 10px;
  color: var(--secondary-color);
  margin-left: 4px;
  line-height: normal;
  position: absolute;
  right: -53px;
  top: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; /* Ensure the navbar is on top of other content */
  transition: all 0.3s ease-in-out;
}

/* Mobile */
@media (max-width: 700px) {
  .navbar1 {
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}
