.navbar {
    position: relative;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar .menuBtn {
    border: 1px solid var(--grey-1-color);
    padding: 8px 16px;
    border-radius: 24px;
    color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--white-color);
    transition: all 0.3s ease-in-out;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-color: rgb(5 41 61 / 80%);*/
    /*background-color: rgb(255 255 255 / 50%);*/
    background-image: radial-gradient(#0e3346 10%, rgb(5 41 61 / 85%) 20%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Put the modal at the very front */
    padding: 0px; /* Add padding to create the margin for modalContent */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    backdrop-filter: blur(8px);
    animation: blurFadeIn 0.3s ease-out;
  }
  
  .ribbonText {
    position: relative;
    line-height: 1.67rem;
    font-family: var(--font-mono);
    font-size: var(--regular-6-size);
  }
  .ribbon {
    align-self: stretch;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    min-height: 4rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xl) var(--padding-5xl);
  }
  
  .modalContent {
    /*background-color: var(--white-color);*/
    width: 100%; /* Full viewport width with 20px margin on each side */
    height: 100%;
    /*margin: 20px;*/
    max-width: var(--max-width);
    overflow-y: auto;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    display: flex;
    flex-direction: column;
  }
  
  .modal h2 {
    margin: 0 0 10px 0;
  }
  
  .modal a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .modalHeader {
    color: var(--secondary-color);
  }
  
  .modalHeader,
  .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-5xl) 0;
    flex-shrink: 0; /* Prevent flex items from shrinking below their initial size */
  }
  
  .cwLogo {
    position: relative;
  }
  
  .vectorIcon {
    position: relative;
    width: 16.3rem;
    /*height: 3rem;*/
    flex-shrink: 0;
    /*mix-blend-mode: multiply;*/
    vertical-align: middle;
  }
  
  .cwBetaBadge {
    padding: 4px 8px;
    text-align: center;
    border-radius: 20px;
    background-color: var(--primary-color);
    font-family: var(--font-mono);
    font-size: 10px;
    color: var(--secondary-color);
    margin-left: 4px;
    line-height: normal;
    position: absolute;
    right: -53px;
    top: 0;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .modalFooter {
    /*border-top: 1px solid var(--grey-2-color);*/
    gap: 20px;
  }
  
  .modalTitle {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-family: var(--font-mono);
    font-size: var(--regular-6-size);
  }
  
  .modalBody {
    overflow-y: auto;
    padding: 20px 0;
    gap: 24px;
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    flex: 1;
    box-sizing: border-box;
  }
  
  .closeButton {
    cursor: pointer;
  
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .section {
    color: var(--primary-color);
    border-top: 1px solid var(--primary-color);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-family: var(--font-mono);
    font-size: var(--regular-6-size);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 8px;
  }
  
  .category {
    font-family: var(--font-primary);
    font-size: 2rem;
    color: var(--white-color);
    font-weight: 600;
    text-transform: none;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .subcategory {
    font-family: var(--font-primary);
    letter-spacing: normal;
    font-weight: normal;
    text-transform: none;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
    border: 1px solid transparent;
    font-size: 1.6rem;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;
    text-align: center;
    background-color: rgb(255 255 255 / 23%);
    transition: all ease-in-out 0.2s;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
  
  .subcategory:hover {
    background: linear-gradient(var(--secondary-color), var(--secondary-color))
        padding-box,
      linear-gradient(
          60deg,
          var(--purple-color),
          var(--primary-color),
          var(--green-1-color),
          var(--yellow-color)
        )
        border-box;
    border: 1px solid transparent;
  }
  
  .one_column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }
  
  .two_columns {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex: 1 0 calc(50% - 12px);
  }
  
  .categoryItems {
  }
  
  .categoryItems .section {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .section > :first-child {
    flex: 1 0 100%;
    color: var(--primary-color);
  }
  
  .categoryItems .section .category {
    flex: 1 0 calc(50% - 8px);
  }
  
  @keyframes blurFadeIn {
    from {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(8px);
    }
  }
  